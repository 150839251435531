const isInternalLink = (href) => {
  return (
    !href ||
    href.startsWith("#") ||
    href.startsWith("data:") ||
    href.startsWith("/") ||
    href.includes("digitalnz.org") ||
    href.includes("localhost") ||
    href.includes(window.location.host)
  );
};

const isExternalLink = (href) => {
  return !isInternalLink(href);
};

export { isExternalLink, isInternalLink };
